html {
  font-size: 16px; // rem will calculate from here
}

//global root css
:root {
  --color-1: #00c3d9;
  --color-2: #6239bf;
  --color-3: #1c1e56;
  --color-4: #00d994;
  --color-5: #df3868;
  --color-6: #f4f6f9;
  --color-7: #e8edf3;
  --color-8: #d1dbd8;
  --color-9: #aab9ce;
  --color-10: #89dead;
  --color-11: #28c777;
  --color-12: #e87395;
  --color-13: #000000;
  --color-14: #193446;
  --color-15: #3e3e3b;
  --color-16: #dfe2d2;
  // --color-17: #6d7973;
  --color-17: #b3ad57c9;
  --color-18: #ffc107;
  --color-19: #fad02c;
  --color-20: #d0ebff;
  --color-21: #2bb575;
  --color-22: #AFAFAF;
  --color-23: #747C91;  
  --color-24: #ffffff;  
  --color-25: #F7F7FB;  
  --color-26: #DC3545;  
  --color-27: #FFD8D8;
  --color-28: #E7FAF1;  
  --color-29: #D17A67;  
  --color-30: #FBEEDF;  
  --sm: 2px;
  --lg: 3px;
  --xl: 4px;
  --xxl: 5px;
}

body {
  font-family: "Rubik", sans-serif;
  font-size: 0.87rem; // 14px
  line-height: 1.5;
}

.app_layout {
  margin-top: 55px !important;
}

//new css based on color pallete


input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"],
textarea {
  outline: none !important;
  box-shadow: none !important;
}

.form-control {
  outline: none !important;
  box-shadow: none !important;
  border-radius: 5px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  >.col,
  >[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.nav-pills>li {
  text-align: center;
}

// common class
.cursor {
  cursor: pointer;
}

.de_active {
  pointer-events: none;
  opacity: 0.54;
}

.has-search .form-control-search {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.error {
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #dc3545;
}

// modals
.al_modal {
  padding: 10px;
  //background: #1c334a;
  background-color: #14213d !important;
  color: #fff;

  .close {
    color: #fff;
  }
}

.al_modal_Size {
  .modal-dialog {
    max-width: 75%;
    margin: 1.75rem auto;
  }
}

// all datePicker
.al_datePicker {

  // cursor: pointer;
  // border: 0;
  // border-radius: 4px;
  // /* background-color: #216ba5; */
  // font: inherit;
  // color: #212529;
  // border: solid 1px gray;
  .react-datepicker__month--selected {
    color: #ffff;
    background-color: #14213d !important;
  }

  .react-datepicker__day--selected {
    border-radius: 1.3rem;
    background-color: #14213d !important;
    color: #fff;
  }

  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    //  display: inline-block;
    width: 4rem !important;
    margin: 3px !important;
    height: 30px;
    padding-top: 6px !important;
  }

  .react-datepicker__header {
    background-color: #aaccccfa !important;
  }

  .react-datepicker__triangle {
    border-bottom-color: black !important;
  }

  .react-datepicker__navigation--next {
    right: 10px;
    border-left-color: black;
  }

  .react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: black;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    border-top-color: black !important;
  }
}

// table
.al_table thead th {
  // color: var(--color-13) !important;
  color: #ffff !important;
  font-weight: bold !important;
  font-size: 0.8rem;
  padding: 1rem;
}

.al_table thead tr th {
  //  background: var(--color-9);
  // background: #14213d;
  //background: rgba(112, 190, 163, 1);
  background: #2BB575 !important;
  //background-color: var(--color-4) !important;
}

.al_table td {
  font-size: 0.8rem !important;
  padding: 1rem !important;
  // font-weight: bold;
}

.al_table .table_footer {
  // background: #3f87a6 !important;
  // background-color: var(--color-15) !important;
  background-color: rgba(112, 190, 163, 1) !important;
}

.al_table_1 {
  overflow-x: auto;
}

.text-primary {
  color: #48b2ca !important;
}

.text-danger {
  color: var(--color-5);
}


.bg_clr_21 {
  background-color: var(--color-21) !important;
}

.bg_clr_24 {
  background-color: var(--color-24) !important;
 }

.bg_clr_25 {
  background-color: var(--color-25) !important;
}

.bg_clr_26 {
  color: var(--color-26) !important;
 }

.bg_clr_27 {
  background-color: var(--color-27) !important;
}

.bg_clr_28 {
  background-color: var(--color-28) !important;
}

.bg_clr_30 {
  background-color: var(--color-30) !important;
}

.text_clr_1 {
  color: var(--color-1) !important;
}

.text_clr_2 {
  color: var(--color-2) !important;
}

.text_clr_3 {
  // color: var(--color-3) !important;
  color: rgba(238, 195, 142, 1) !important
}

.text_clr_4 {
  color: var(--color-19) !important;
}

.text_clr_5 {
  color: var(--color-5) !important;
}

.text_clr_6 {
  color: var(--color-6) !important;
}

.text_clr_7 {
  color: var(--color-7) !important;
}

.text_clr_8 {
  color: var(--color-8) !important;
}

.text_clr_9 {
  color: var(--color-9) !important;
}

.text_clr_10 {
  color: var(--color-10) !important;
}

.text_clr_11 {
  color: var(--color-11) !important;
}

.text_clr_12 {
  color: var(--color-12) !important;
}

.text_clr_13 {
  color: var(--color-13) !important;
}

.text_clr_14 {
  color: var(--color-15) !important;
}

.text_clr_15 {
  color: rgba(112, 190, 163, 1) !important // color:rgba(81, 153, 141, 1) !important
}

.text_clr_21 {
  color: var(--color-21) !important;
  }

.text_clr_22 {
  color: var(--color-22) !important;
}

.text_clr_23 {
  color: var(--color-23) !important;
}

.text_clr_24 {
  color: var(--color-24) !important;
 }

 .text_clr_26 {
  color: var(--color-26) !important;
 }

 .text_clr_29 {
  color: var(--color-29) !important;
 }

.al_table_1 tbody {
  display: block;
  max-height: 60vh;
  overflow-y: scroll;
}

.al_table_1 thead,
.al_table_1 tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.al_table_2 tbody {
  display: block;
  max-height: 60vh;
  overflow-y: scroll;
}

.al_table_2 thead,
.al_table_2 tbody tr,
.al_table_2 tfoot tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.al_table_3 {
  width: 100%;

  thead tr th {
    position: sticky;
    vertical-align: bottom;
    top: 0;
    border: 1px solid #dee2e6;
    min-width: 105px;
    height: 40px;
    // background-color: var(--color-15) !important;
    background-color: #14213d !important;
    box-sizing: border-box;
    color: #ffffff !important;
  }

  thead tr th:hover {
    background-color: var(--color-15) !important;
    color: #ffffff !important;
  }

  // .table_child_header {
  //   th {
  //     border: unset !important;
  //     border-right: 1px solid #dee2e6 !important;
  //   }
  // }
}

.al_table_3 td {
  min-width: 105px;
  border: 1px solid #dee2e6;

  // border-collapse: collapse;
  text-align: center;
}

// used table 4 in holdings

.table_child {

  // border: unset !important;
  // border-left: 1px solid red !important;
  tr {

    // border: unset !important;
    td,
    th {
      border: unset !important;
      border-right: 1px solid #dee2e6 !important;
    }
  }
}

.al_table_4 {
  width: 100%;
}

.al_table_4,
th,
td {
  //border: 1px solid #aab9ce;
  font-size: 12px;
  min-width: 85px;
  border-collapse: collapse;
  text-align: center;
}

.al_table_4 thead tr th {
  position: sticky;
  vertical-align: bottom;
  top: 0;
  //background-color: var(--color-15) !important;
  background-color: #14213d !important;
  // background-color: #e8f1f5;
  box-sizing: border-box;
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  color: #ffff !important;
  // color: #161616;
}

.al_table_4 {
  th:first-child {
    left: -16px;
    z-index: 1;
  }

  th:nth-child(2) {
    left: 70px;
    z-index: 1;
  }
}

.al_table_4 {
  td:nth-child(1) {
    position: sticky;
    left: -16px;
    background-color: #ffff;
  }

  td:nth-child(2) {
    position: sticky;
    left: 70px;
    white-space: nowrap;
    background-color: #ffff;
  }
}

// used in holing InvestmentSummary
.al_table_4 .table_footer {
  background-color: #14213d !important;
}

// css to transform a to button
.btn_link {
  border: none;
  background-color: transparent;
  font-size: 22px;
  color: white;
  height: 60px;
}

.btn_group {
  .btn-default {
    background: #ab987a;
  }

  .btn-circle {
    width: 40px;
    height: 40px;
    padding: 6px 0px;
    border: 1px solid lightgrey;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
    transition: width 0.2s, height 0.2s;
    color: white;
  }

  // .btn-default {
  //   background: white;
  // }
  .btn-circle:hover {
    background: #ccc5f0;
    font-weight: 500;
    border: 2px solid #ccc5f0;
    color: black;
  }

  .active {
    background: #ccc5f0;
    width: 35px;
    height: 35px;
    font-weight: 500;
    border: 2px solid #ccc5f0;
    color: black;
  }
}

.al_auto_search {
  div {
    display: block !important;
  }

  input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    padding-left: 2.375rem;
    font-size: 1rem;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
  }

  input+div {
    z-index: 1000;

    div {
      font-size: 1rem;
      padding: 8px 10px;
    }
  }
}

.al_auto_search_1 {
  input {
    border-radius: 20px;
    outline: none;
  }

  .input:focus {
    color: #495057;
    background-color: #fff;
  }
}

.bg-dark {
  // background: #0a1612;
  background: #0a1612;
}

.bg_1 {
  // background: rgba(232, 242, 238, 1) !important;
  background: #f7f6f6 !important;
}

.bg_2 {
  background: #8b9dd0;
}

.bg_clr_2 {
  background-color: #E6F4F1;
}

.bg_active {
  background: var(--color-4)
}

.bg_disabled {
  background: var(--color-5)
}

.bg_3 {
  //background:#78c3bc
  background: var(--color-15) !important;
}

.bg_4 {
  //background:#78c3bc
  background: #ffc1072b !important;
  // background: #c3edc68c !important;
}

.bg_clr_4 {
  background-color: #70bea324 !important;
}

// .bg_5 {
//   //background:#78c3bc
//   //background: var(--color-17) !important;
//   background: #0000ff17 !important;
// }

.bg_6 {
  //background:#d0ebff
  background: var(--color-20) !important;
}

.bg_clr_6 {
  background: rgba(112, 190, 163, 1);
}

.bg_clr_7 {
  background: rgba(244, 242, 216, 1);
}

.bg_7 {
  background: #14213d;
  color: white;
}

// .bg_8 {
//   background: #dededec2 !important;
// }

.b-clr {
  background: #0a1612;
}

.b-clr1 {
  color: #fff;
  font-weight: 600;
}

.h-20 {
  height: 20px;
}

.w-16 {
  width: 16%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-24 {
  width: 24%;
}

.ml-1 {
  margin-left: var(--xs)
}

.ml-2 {
  margin-left: var(--sm)
}

.ml-3 {
  margin-left: var(--lg)
}

.ml-4 {
  margin-left: var(--xl)
}

.ml-5 {
  margin-left: var(--xxl)
}

.ml-30 {
  margin-left: 30px;
}

.ml-68 {
  margin-left: 68px;
}

.ml-130 {
  margin-left: 130px;
}

.mr-30 {
  margin-right: 30px;
}

.mt-6 {
  margin-top: 6px;
}

.mt_100 {
  margin-top: 100px !important;
}

.mt_150 {
  margin-top: 150px !important;
}

.mt_90 {
  margin-top: 90px;
}

.mt_80 {
  margin-top: 80px;
}

.mt_60 {
  margin-top: 60px !important;
}

.mt_30 {
  margin-top: 30px;
}

.mb_80 {
  margin-bottom: 80px;
}

.fw_700 {
  font-weight: 700 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fs-25 {
  font-size: 25px;
}

.fs-22 {
  font-size: 22px;
}

.fs-26 {
  font-size: 26px;
}

.fs-20 {
  font-size: 20px;
}
.fs-21 {
  font-size: 21px;
}

.fs-28 {
  font-size: 28px;
}

.fs-24 {
  font-size: 24px;
}

.fs-16 {
  font-size: 16px;
}

.fs-15 {
  font-size: 15px;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.fs-18 {
  font-size: 18px;
}

.fs-30 {
  font-size: 30px;
}

.fs-32 {
  font-size: 32px;
}

.fs-36 {
  font-size: 36px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.br_7 {
  border-radius: 7px;
}

.br_12 {
  border-radius: 12px;
}

.bb-1 {
  border-bottom: 1px solid #70d452;
}

.bb_2 {
  //border-bottom: 3px solid orange;
  border-bottom: 3px solid #2BB575 ;
}

.bb-2 {
  border-bottom: 1px solid #D9D5EC;
}

.bb-3 {
  border-bottom: 1px solid #91D9B7;
}

.txt-al-c {
  text-align: center;
}

.sub_title {
  color: rgb(114, 118, 130);
  font-size: 1rem;
}

.al_inline_input {
  display: inline-block;
  width: auto;
  border: 0px;
  border-bottom: 1px dashed;
  border-radius: 0px;
  padding: 0px;
  margin: 0px 10px;
}


.w-20 {
  width: 20%;
}

.ml-30 {
  margin-left: 30px;
}

.ml-68 {
  margin-left: 68px;
}

.ml-130 {
  margin-left: 130px;
}

.txt-al-c {
  text-align: center;
}

.inline_style_1 {
  border: 2px solid lightblue;
  padding: 10px;
  border-radius: 20px;
}

.block_style_1 {
  border: 2px solid lightseagreen;
  padding: 20px;
  border-radius: 20px;
}

.box_style_1 {
  // border: 1px solid lightgray;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background: #fffaf0;
}

.box_style_1:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) inset;
}

.box_style_2 {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); // background: #005580
  background: #94bdd2;
}

.box_style_3 {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background: #94bdd2;
}

.box_style_4 {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background: #ffffff;
}

.box_style_5 {
  border-color: #2BB575;
  // border-color: #fb9c46 !important;
  // border-color: var(--color-18) !important;
  // border-color: var(--color-4) !important;
  // border-color: #d3d3d3;
  border-width: 1px 1px;
  border-style: solid;
  // padding: 5px;
  background: #f7f6f6;
  font-weight: normal;
}


.box_style_5.active {
  border-top: 3px solid #2BB575 !important;
  border-bottom: 3px solid #2BB575 !important;
  // border-top: 3px solid #fb9c46 !important;
  // border-bottom: 3px solid #fb9c46 !important;
  // border-top: 3px solid var(--color-18) !important;
  // border-bottom: 3px solid var(--color-18) !important;
  // border-top: 3px solid var(--color-4) !important;
  // border-bottom: 3px solid var(--color-4) !important;

  // border-top: 3px solid #add8e6;
  // border-bottom: 3px solid #add8e6;
  background: #f7f6f6; // border-bottom:0px;
}

.box_style_5:hover {
  border-top: 3px solid #2BB575 !important;
  border-bottom: 3px solid #2BB575 !important;
  // border-top: 3px solid #fb9c46 !important;
  // border-bottom: 3px solid #fb9c46 !important;
  // border-top: 3px solid var(--color-4) !important;
  // border-bottom: 3px solid var(--color-4) !important;
  //  border-top: 3px solid #add8e6;
  // border-bottom: 3px solid #add8e6;
  font-weight: bold;
}

.box_style_6 {
  width: 750px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  background: rgb(247, 246, 246);
}

.box_style_7 {
  padding: 10px;
  border-radius: 5px;
  padding: 20px
}

.box_style_8 {
  background: white !important;
  color: white;
  border: 3px solid var(--color-18) !important;
}

.box_style_9 {
  padding: 10px;
  text-align: center;
  border-radius: 20px;
  border: #94bdd2 !important;
  border: 3px solid !important;
}

.ellipse_box {
  background: whitesmoke;
  border-radius: 20px;
  padding: 10px;
  color: black;
  font-weight: 500;
  border: 1px solid black;
}

.rounded_box {
  width: 180px;
  padding: 45px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  background: #ffffff;
  text-align: center;
  border: 1px solid black;
}

.title_1 {
  font-size: 1.64rem;
  line-height: 26.91px;
  word-spacing: 0px;
  text-decoration: none solid rgb(10, 39, 69);
  color: #0a1612;
  font-weight: 700;
  display: inline-block; // border-bottom: 1px solid gold
}

.title_2 {
  font-size: 1.71rem;
  word-spacing: 0px;
  font-weight: 700;
  line-height: 25.7143px;
  -webkit-text-decoration: none solid #0a2745;
  text-decoration: none solid #0a2745;
  color: #14213d !important;
  cursor: pointer;
  text-align: left;
}

.title_3 {
  font-size: 1rem;
  word-spacing: 0px;
  font-weight: 700;
  text-decoration: none solid rgb(10, 39, 69);
  color: #00c3d9;
}

.title_4 {
  font-size: 1.4rem;
  word-spacing: 0px;
  font-weight: 700;
  text-decoration: none solid rgb(10, 39, 69);
  color: #23527c;
}

.title_5 {
  font-size: 1rem;
  word-spacing: 0px;
  font-weight: 700;
  text-decoration: none solid rgb(10, 39, 69);
  letter-spacing: 1px;
  color: #0a2745;
}

.title_6 {
  font-size: 16px;
  font-weight: 500;
  color: #17456e;
  // color: var(--color-4) !important;
  white-space: nowrap;
}

.border_bottom_2 {
  border-bottom: var(--color-1) 1px solid;
}

.border_width_3 {
  border-width: 3px !important;
}

.subtitle_1 {
  font-size: 0.714rem;
  // color: rgb(134, 136, 143);
  border: 1px solid lightgrey;
  padding: 5px 10px;
  border-radius: 5px;
}

.subtitle_2 {
  font-size: 0.714rem;
  color: rgb(134, 136, 143);
  border: 1px solid lightgrey;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 500;
}

.par_0 {
  color: #14213d !important;
}

.par_1 {
  font-size: 15px;
  color: rgb(134, 136, 143);
}

.par_2 {
  font-size: 16px;
  font-weight: 500;
  display: inline;
  color: #575a60;
}

.par_3 {
  font-size: 0.87rem;
  font-weight: 500;
  color: #3f5166;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.par_4 {
  font-size: 0.875rem;
  font-weight: 400;
  color: #000000;
}

.par_5 {
  font-size: 0.71rem;
  font-weight: 500;
  color: #0a1612;
}

.par_6 {
  font-size: 1.428rem;
  font-weight: 600;
  //color: rgb(56, 188, 206);
  //color: var(--color-4) !important;
  color: #14213d !important;
  border-radius: 10px;
}

.par_7 {
  font-size: 0.8rrem;
  font-weight: 600;
  color: black;
  border-radius: 10px;
}

.par_8 {
  color: #2b3d51;
  font-size: 1rem;
}

.par_9 {
  color: #38393a;
  font-size: 0.8rem;
  font-weight: 500;
}

.par_10 {
  font-size: 0.8rem;
  font-weight: 500; // color: #acb3b9;
  color: black;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.par_11 {
  word-spacing: 0px;
  font-weight: 500;
  text-decoration: none solid rgb(10, 39, 69);
  color: #00c3d9;
}

.par_12 {
  font-weight: 300;
  text-decoration: none solid rgb(10, 39, 69);
  color: #00c3d9;
}

.par_13 {
  font-size: 16px;
  font-weight: 500;
  display: inline;
  color: #17a2b8;
  // color: var(--color-4) !important;
}

.par_14 {
  font-size: 1.428rem;
  font-weight: 600;
  color: var(--color-15) !important;
  border-radius: 10px;
}

.par_15 {
  font-size: 1.428rem;
  font-weight: 500;
  color: var(--color-15) !important;
}

.par_16 {
  font-size: 1.428rem;
  font-weight: 250;
  color: var(--color-15) !important;
  // border-radius: 10px;
}

.par_17 {
  font-size: 34px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  color: #000000;
}

.par_18 {
  line-height: 1.43;
}

.par_19 {
  font-size: 0.8rrem;
  font-weight: 600;
  color: #14213d;
}

.al_card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px !important; //   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  // padding-bottom: 5px !important
}

.al_card:hover {
  box-shadow: 5px 5px 50px -40px #0a1612 inset;
}

.active_card {
  box-shadow: 5px 5px 50px -40px #0a1612 inset;
}

.al_card_1 {
  padding: 5px 20px 5px 20px; // color: #666666;
}

.al_card_1 .card-title {
  font-size: 18px;
  word-spacing: 0px;
  font-weight: 700;
  line-height: 25.7143px;
  text-decoration: none solid rgb(10, 39, 69);
  //color: #23527c;
  color: black;
  cursor: pointer;
  text-align: left;
}

.al_card_2 {
  border: 0px solid black;
  padding: 15px 15px; // box-shadow: rgba(128, 152, 213, 0.06) -6px 5px 10px 10px;
}

.al_card_2 .card-header {
  border-bottom: 0px solid black;
  font-size: 18px;
  font-weight: 500;
  color: #45bad8;
}

// al_card_3
.al_card_3 .card-header {
  font-weight: 700;
  color: black;
}

.al_card_4:hover {
  // box-shadow: 0px 0px 0px 0px #0a1612 inset;
  box-shadow: inset 0px 0px 0px 0px red;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.al_card_5 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px !important; //   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  // padding-bottom: 5px !important
}

.al_card_5 .card-header {
  //  background: white;
  font-weight: bold;
  //  border-top-left-radius:10px;
  //  border-top-right-radius:10px
}

.al_card_6 {
  padding: 1.2em;
  text-align: center;
  transition: all 0.2s ease-in-out;
}

.al_card_6:hover {
  // background-color: #c9e9f7;
  // border-top: 2px solid #c9e9f7;
  // border-bottom: 2px solid #c9e9f7;
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 1);
  transform: scale(1);
  z-index: 2;
}

// al_card_7
.al_card_7 .card-header {
  font-weight: 700;
  background: #ffff;
  color: black;
}

.al_card_8 {
  box-shadow: 0px -1px 8px 0 rgb(0, 0, 0);
  border-radius: 10px !important; //   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  // padding-bottom: 5px !important
}

.al_card_8:hover {
  box-shadow: 0px 0px 16px 1px #ffc107;

  .card-header {
    //background:#78c3bc
    background: var(--color-8) !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
  }

  .card-footer {
    background: var(--color-8) !important;
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }
}

.al_card_10 {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 30px !important;
  cursor: pointer;
  position: relative;
  text-align: center;
  padding: 31px;
  width: 100%;
  border: solid 4px #e5e5e5;
}

.al_card_11 {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 30px !important;
  cursor: pointer;
  position: relative;
  text-align: center;
  border: solid 4px #e5e5e5;
  height: 316px;
  margin: 14px;
  width: 277px;
}

//DATA AND HEADER
.data_1 {
  font-size: 14px;
  color: #141b2f;
  font-weight: 500;
}

.header_1 {
  font-size: 12px;
  color: #494b51;
}

.nav_default {
  background: steelblue;
}

.img_style_1 {
  border: 0px solid lightgrey;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); // box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.box_style_1 {
  border: 1px solid lightgrey;
  padding: 10px;
}

#side_menu {
  box-shadow: 5px 5px 40px -10px #888888;

  .active {
    // background: #5891c1;
    background: #696969;
    font-weight: 700;
    color: white;
  }

  .nav-link {
    padding: 1rem;
  }
}

//css grid layout
.grid-container {
  display: grid;
  grid-template-columns: 250px 1fr;
  // grid-gap: 0px 5px;
  grid-gap: 0px 1px;
  background-color: lightgrey;
  width: 100%; // height: calc(100vh-50px);
  height: auto;
}

.grid-container .grid_item1 {
  background: rgb(219, 232, 235);
  // background: #14213d !important;
  // background: #2a2b2c !important;
  //background: var(--color-14);
  text-align: center;
  font-size: 20px;
  height: calc(100vh - 60px);
  //  overflow-y: auto
}

.grid-container .grid_item2 {
  background: #fff !important;
  font-size: 14px;
  //height: calc(100vh - 60px);
  overflow-y: auto; // font-size: 14px;
}

#sidebar_menu_bar {
  .list-group-item {
    // background: #2a2b2c !important;
    background: rgb(219, 232, 235);
    // background: var(--color-14);
    //background: rgba(0, 0, 0.1, 0) !important;
    // border-top: 1px solid #fff;
    // border-bottom: 1px solid #fff;
    cursor: pointer;
    padding: 0.7rem 0.25rem;
    // border-right: unset !important ;
    border: unset !important;
  }

  .background_color {
    //background: var(--color-14);
    // background: #14213d !important;
    // background: #2a2b2c !important;
    // color: var(--color-18) !important;
    //background: rgba(81, 153, 141, 1) !important;
    background: white;
    color:#2BB575 !important;
  }

  .sidebar_menu_bar_text {
    // color: black unset !important;
    //color: white !important;
    font-size: 14px;
    white-space: nowrap;
    font-weight: 500;
  }

  //   .idebar_menu_text_clr {
  //     color: #fb9c46;
  // }

  .inactive_text_color {
    //color: var(--color-17) !important;
    // color: white !important;
    color: rgb(22, 21, 21) !important
  }

  .sidebar_menu_bar_text:hover {
    //color: #9ACD32 !important;
    // color: var(--color-18) !important;
    // color: rgb(91, 181, 162) !important
    color: #2BB575 !important;
    // border-bottom: 1px solid #00c3d9;
  }

  .active {
    //color: #9ACD32 !important;
    //color: #fb9c46 !important;
    color: #2BB575 !important;
    // color:rgb(91, 181, 162) !important;
    // border-color: unset !important;
  }
}

.p-30 {
  padding-left: 30px;
}

.text-primary {
  color: #48b2ca !important;
}

.icon_circular {
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0px 0px 2px #888;
  padding: 0.5em 0.6em;
}

.image_circular {
  border: 3px solid lightgray;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.image_circular_2 {
  border: 1px solid lightgray;
  border-radius: 50%;
}

.fa_icon_1 {
  font-size: 20px !important;
}

.rupees_icon {
  color: var(--color-18) !important;
}

//tabs styling
.al_tabs_1 .nav-tabs .nav-link {
  // background: var(--color-7);
  background: var(--color-16);
  border-radius: 10px 10px 0px 0px;
  margin-right: 15px;
  color: var(--color-13);
}

.al_tabs_1 .nav-tabs .nav-link:hover {
  background: var(--color-17);
  // background: var(--color-3);
  color: white;
  font-weight: 500;
}

.al_tabs_1 .nav-tabs .nav-link.active {
  background: var(--color-17);
  // background: var(--color-3);
  color: white;
  font-weight: 500;
}

.al_tabs_2 {
  border-bottom: solid 2px #e9ecef;
  // font-weight: 500;
  font-size: 15px;
  white-space: nowrap;
  cursor: pointer;

  // padding-bottom: 8px;
  .active {
    // color: #fb9c46 !important;
    color: #2BB575 !important;
    padding-bottom: 8px;
    font-weight: 600;
    // color: var(--color-19);
    // border-bottom: solid 3px var(--color-19) !important;
    // border-bottom: solid 3px #fb9c46 !important;
    border-bottom: 3px solid #2BB575 !important;
  }
}

.border_bottom_1 {
  border-bottom: 1px solid #23527c !important;
}


.mh_1 {
  max-height: 337px;
}

//---------------------------------------------------
.fs_1 {
  font-size: 1rem;
}

.text_muted {
  color: #dbdbdb;
}

.bg_card {
  background: #fffaf045;
}

.card_rd_1 {
  border-radius: 0.7em !important;
}

.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.01);
}

.shadow_1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

//border
.border_bottom {
  border-bottom: 1px solid #d1dbd8;
}

//curson
.cursor_1 {
  cursor: pointer;
}

//width
.w_90 {
  width: 90%;
}

// .custom_input_option {
//   border: 2px solid gray;
//   border-radius: 20px;
//   padding: 15px;
// }

// .custom_input_option.active {
//   color: #000 !important;
//   background: var(--color-19) !important;
//   border: 2px solid gray;
// }

.fixed_btn {
  right: 3%;
  top: 50%;
  position: fixed;
}

.scrolling-wrapper {
  overflow-x: scroll;
  white-space: nowrap;
}

.img_fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.col_custom {
  width: 16.66% !important;
}

@media only screen and (max-width: 500px) {
  .col_custom {
    width: 90% !important;
  }
}

.rounded_0 {
  border-radius: 0px !important;
}

.al_dropdown {
  li {
    background: #e0e0e0;
    border-bottom: 0.5px solid #8080806e;
    padding: 5px;
    color: black;
    text-decoration: none;

    &:hover {
      border-bottom: 2px solid rgba(4, 0, 255, 0.705);
    }
  }
}

// .active_button_ {
//   color: white;
//   background-color: green !important;
// }

//for custom width
.w_60 {
  width: 60%;
}

.w_40 {
  width: 40%;
}

.w_42 {
  width: 42%;
}

.w_45 {
  width: 45%;
}

.w_30 {
  width: 30%;
}

.w_25 {
  width: 25%;
}

.w_35 {
  width: 35%;
}

.p_80 {
  padding: 81px !important;
}

//custom icon
.calender_icon {
  font-size: 30px;
  margin-top: -45px;
  position: fixed;
  margin-left: 154px;
  color: cadetblue;
}



.al_custom_inputBox {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #1bb18b !important
}

.sticky_Div {
  position: sticky;
  top: 20px !important;
}

.grid_item3 {
  font-size: 14px;
  height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 102% !important;
}

.progressStatus ul {
  text-align: center;
  margin: auto
}

.progressStatus li {
  list-style-type: none;
  width: 12.5%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}

.progressStatus ul li .fa {
  background: #ccc;
  width: 16;
  height: 16;
  color: #fff;
  border-radius: 50%;
  padding: 8px
}

.progressStatus ul li .fa::after {
  content: '';
  background-color: #ccc;
  height: 5px;
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 10px;
  z-index: -1
}

// .progressStatus ul li i.active{
// // color:#148e14
// }

.progressStatus ul li .fa.active {
  // background-color:#148e14
  background-color: var(--color-1)
}

.progressStatus ul li .fa.pending {
  background-color: var(--color-12)
}

.progressStatus ul li .fa.done {
  // background-color: var(--color-4)
  background-color: #14213d !important;
}

.progressStatus ul li .fa.done::after {
  // background-color: var(--color-4)
  background-color: #14213d !important;
}

.progressStatus ul li:last-child .fa::after {
  width: 70px;
}

.progressStatus ul li:first-child .fa::after {
  width: 300px;
  left: 60px
}

.custom_tab {
  .nav-item.nav-link {
    //  color: #fb9c46 !important;
    color: #000000;
    // font-weight: 500 !important;
    font: 13px "Rubik", sans-serif;
    border: 0 !important;
    cursor: pointer;
    font-size: 15px;
  }

  .nav-item.nav-link.active {
    // color: #fb9c46 !important;
    color: #2BB575 !important;
    font-weight: 600 !important;
    // border-bottom: 3px solid #fb9c46 !important;
    border-bottom: 3px solid #2BB575 !important;
  }

}

.custom_tab_1 {
  .nav-item.nav-link {
    color: #14213D !important;
    font-weight: 500 !important;
    font: 15px "Rubik", sans-serif;
    border: 0 !important
  }

  .nav-item.nav-link.active {
    color: var(--color-18) !important;
    font-weight: 700 !important;
    border-bottom: 3px solid var(--color-18) !important;
  }
}

.form_control_custom {
  border-bottom: 1px solid lightgrey !important;
  border-radius: 0
}

.input_number {
  border-bottom: 1px solid lightgrey !important;
  border-radius: 0;
  width: 45px;
  text-align: center;
  border: none;
  color: chocolate;
  font-size: 25px;
  width: 60px;
  height: 26px;
}

//remove the increment decrement from the input if type===number
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//--------------------------------------------------------------------
// .text_clr_15 {
//   padding: 16px;
//   color: #9ACD32;
//   border: 2px solid #9ACD32;
//    border-radius: 4px;
//   font-weight: 600;

//   // background-image: linear-gradient(to right, #c2ff72fa 1%, #95F985 );
// }
// .banner_1 {
//   color: white;
//   padding: 16px;
//   font-weight: 600;
//   border-radius: 4px;
//   //background: #9ACD32;
//   background:#14213d !important;
// }

.banner_2 {
  padding: 16px;
  font-weight: 600;
  color: #14213d;
  background: #ffc10721 !important;
  border: 2px solid #14213d !important;
}

//Show/Hide Password
.eye-icon {
  position: absolute;
  top: 46px;
  right: 30px;
  cursor: pointer;
}

.text_editor {
  resize: none;
  border: none;
}

.text_editor:hover {
  border: 2px solid #040116;
}

// auto collapse bootsrap dropdown

.display_collapse {
  .collapse.show {
    display: none !important;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .125);
  }

  :hover {

    // background-color: yellow;
    .collapse.show {
      display: block !important;
      position: absolute;
      background: white;
      margin-top: 47px;
    }
  }
}

.box_legend {
  width: 10px;
  height: 10px;
}

.box_color_1 {
  background-color: #fba210;
}

.box_color_2 {
  background-color: #0e234e;
}

.box_color_3 {
  background-color: #f94144;
}

.box_color_4 {
  background-color: #0A9396;
}

.custom-scrollbar {
  position: relative;
  height: 800px;
  overflow: auto;
}

.table-header-fixed {
  position: sticky;
  top: 0;
  background-color: #dee2e6;
}

.chartWidth {
  width: 100%;
  min-height: 400px;
}


.image_preview {
  width: 100%;
  min-height: 400px;
}

.img_hover:hover {
  padding: 5px;
  transition: padding 0.2s linear;
}
 
 .sidenav {
   min-height: 100%;
  //  width: 280px;
   top: 0;
   left: 0;
   position: relative;
   background-color: var(--color-24) !important;
   z-index: 1 !important;
   overflow-x: hidden;
   padding-top: 20px;
   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
 }
 
 .sidenav .sidebar_content {
   padding: 10px;
   font-size: 14px;
   color: #53545C;
   display: block;
 }
 
 .sidenav .sidebar_content:hover {
   padding: 10px;
   color: #f1f1f1;
   border-radius: 12px;
   background: var(--color-21) !important;
   
 }

 .card_goals {
  background: var(--color-24) !important;
  border-radius: 10px;
  text-align: left;
 }

 .card_goalAnalysis {
  border-radius: 12px;
  background-color: var(--color-24) !important;
  box-shadow: 1.789px 1.789px 7.155px 0px rgba(0, 0, 0, 0.25);
}

 .containerStyle {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
 }

 
.goals_active {
  background-color: var(--color-28) !important;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
}

.hover_goals:hover {
  background-color: var(--color-28) !important;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
}

.input_height {
  height: 35px;
}

.overflow_auto {
  overflow: auto;
}

.letter_spacing {
  letter-spacing: -.5px;
}

.overflow_line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.border_asset {
  border-radius: 10px;
  border: 2px solid var(--color-22) !important;
}

 